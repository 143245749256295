
import debounce from "lodash/debounce";
import { PropType } from "vue";
import { prop, Vue } from "vue-class-component";

class Props {
  onNext = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  onPrevious = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  page = prop<number>({
    default: 1,
    type: Number
  });
  isButtonNext = prop<boolean>({
    default: false,
    type: Boolean
  });
  totalData = prop<number>({
    default: 0,
    type: Number
  });
  isShowCaption = prop<boolean>({
    default: true,
    type: Boolean
  });
  toPage = prop<Function>({
    default: Function as PropType<() => void>,
    type: Function
  });
  paginationWidth = prop<string>({
    default: "w-full",
    type: String
  });
  paginationStyle = prop<string>({
    default: "v1",
    type: String
  });
  first = prop<number>({
    default: 0,
    type: Number
  });
  last = prop<number>({
    default: 0,
    type: Number
  });
  availablePage = prop<number>({
    default: 1,
    type: Number
  });
  isThereIsBottonWidget = prop<boolean>({
    default: false,
    type: Boolean
  });
}
export default class Pagination extends Vue.with(Props) {
  defaultValue = "";
  goToPage = debounce(e => {
    if (/^0/.test(e.target.value)) {
      e.target.value.replace(/^0/, "");
    } else {
      e.target.value = e.target.value.replace(/[\D]+/g, "");
      if (e.target.value !== "") {
        this.toPage(e.target.value);
      }
    }
  }, 500);
}
